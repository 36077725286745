<template>
  <ion-header mode="ios" class="ion-no-border" v-if="personalPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="arrowBack" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="md"> Complete appointment booking</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-header mode="ios" class="ion-no-border" v-if="!personalPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <!-- <ion-title class="text-left pl-5" color="primary" style="color:#000">Account name</ion-title> -->
      <div class="text-left pl-2">
        <div class=" ">
          <ion-avatar class="pt-2 float-left">
            <img
              :src="
                imgBaseAvatarUrl +
                'tr:di-default_avatar.png/' +
                currentProvider.avatar
              "
              class="img-fluid user-pict-img"
              alt="Rendez"
            />
          </ion-avatar>
          <div class="user-profile-label pt-2 pl-5 ml-2">
            <div class="username-line">
              <a href="#" class="seller-link">
                {{ currentProvider.username }}
                <i
                  class="fa fa-user-plus pr-1"
                  title="provider is in your circle"
                  v-if="compIsProviderAnInterest"
                ></i>
                <i
                  class="fa fa-handshake-o pr-1"
                  title="trusted provider"
                  v-if="compIsProviderTrusted"
                ></i>
              </a>
            </div>
            <div class="oneliner-wrapper">
              <small class="oneliner">{{ currentProvider.expertise }} </small>
              <small class="oneliner text-primary pl-2">
                <strong>
                  <ion-icon
                    :icon="
                      currentProvider.manual_verification === 'unverified'
                        ? starOutline
                        : star
                    "
                  >
                  </ion-icon>
                </strong>
                {{ currentProvider.manual_verification }}
              </small>
              <div class="ratings-wrapper">
                <p class="rating-text">
                  <i class="fa fa-star pr-1"></i>
                  <strong>{{ currentProvider.rating_all_avg }}</strong>
                  ({{ currentProvider.rating_client_size }} reviews)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ion-buttons slot="end">
        <comp-fav-circle-actions
          :currentProvider="currentProvider"
        ></comp-fav-circle-actions>

        <ion-spinner
          name="bubbles"
          v-if="reqStatus === 'processing'"
        ></ion-spinner>
      </ion-buttons>
    </ion-toolbar>

    <ion-toolbar class="mb-0" v-if="currentBookingView !== 'successfulPreview'">
      <ion-segment :value="currentTab" mode="md" @ionChange="switchTab($event)">
        <ion-segment-button value="book" mode="md">
          <ion-label color="dark">Book </ion-label>
        </ion-segment-button>
        <ion-segment-button value="ratings" mode="md">
          <ion-label color="dark">Reviews</ion-label>
        </ion-segment-button>
        <ion-segment-button value="posts" mode="md">
          <ion-label color="dark">Portfolio</ion-label>
        </ion-segment-button>
        <ion-segment-button value="profile" mode="md">
          <ion-label color="dark">Info</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
    <div
      style="background-color: #fff"
      v-if="
        currentTab === 'book' &&
        currentBookingView === 'schedulePreview' &&
        activeDeliveryModeFilter
      "
    >
      <ion-item lines="none" class="py-3">
        <select
          class="custom-select custom-select-sm border-0 shadow-sm p-1 ml-0"
          required
          v-model.trim="activeDeliveryModeFilter"
        >
          <option
            v-for="(serve, index) in serviceDeliveryMode"
            :key="serve.value + index"
            :value="serve.value"
          >
            {{ serve.alias }}
          </option>
        </select>
      </ion-item>
    </div>
  </ion-header>
  <ion-content class="" :fullscreen="true" mode="ios">
    <div v-if="currentTab === 'book'">
      <create-feed-post-service-booking
        :loadRequiredData="loadRequiredData"
        :currentProviderData="currentProvider"
        :activeDeliveryModeFilter="activeDeliveryModeFilter"
        :accountDataServices="currentProviderServices"
        :currentBookingView="currentBookingView"
        :totalService="compTotalService"
        :totalServiceDuration="compTotalServiceDuration"
        :totalServiceCost="compTotalServiceCost"
        :totalServiceCurrency="compTotalServiceCurrency"
        @emit-close-modal="cancelModal"
        @emit-update-current-provider-services="updateCurrentProviderServices"
        @emit-update-current-services-schedule="updateCurrentServicesSchedule"
        @emit-update-current-booking-view="updateCurrentBookingView"
        @emit-update-active-delivery-mode-filter="
          updateActiveDeliveryModeFilter
        "
        v-if="feedPostBookingKey"
      >
      </create-feed-post-service-booking>

      <div class="container text-center" v-if="!feedPostBookingKey">
        <ion-spinner name="bubbles" class="mt-5"></ion-spinner>
      </div>

      <!-- <create-unauthenticated-feed-post-booking @emit-close-modal="cancelModal"
                                                      :currentProviderData="currentProvider"
                                                      v-if="!isAuthenticated"
            >
            </create-unauthenticated-feed-post-booking> -->
    </div>

    <div class="" v-if="currentTab === 'profile'">
      <account-profile-info
        :accountDataInfo="currentProvider"
        :accountDataServices="currentProviderServices"
        :personalPage="false"
        canvasType="popup"
      >
      </account-profile-info>
    </div>

    <div v-if="currentTab === 'posts'">
      <account-posts
        class="pt-3"
        :ownerId="currentProvider.owner_id"
        postSize="small"
        canvasType="popup"
      ></account-posts>
    </div>

    <div class="" v-if="currentTab === 'ratings'">
      <account-ratings
        class="pt-3"
        :ownerId="currentProvider.owner_id"
      ></account-ratings>
    </div>
  </ion-content>

  <ion-footer
    class="ion-no-border"
    v-if="compTotalService.length > 0 && currentTab === 'book'"
  >
    <ion-toolbar>
      <div
        class="row"
        v-if="
          currentBookingView !== 'completePreview' &&
          currentBookingView !== 'successfulPreview'
        "
      >
        <div
          slot="start"
          class="col-8"
          v-if="selectedServiceType === 'custom_services'"
        >
          <div class="ml-3">
            <h4>
              {{
                compTotalService.length > 1
                  ? compTotalService.length + " services"
                  : compTotalService.length + " service"
              }}
              selected
            </h4>
            {{ tk.timeHourMin(compTotalServiceDuration) }}
            (
            {{
              cash["currencyDetails"][compTotalServiceCurrency.split("-")[0]][
                "symbol_native"
              ]
            }}
            {{ parseInt(compTotalServiceCost).toLocaleString() }} )
          </div>
        </div>
        <div class="col-4">
          <ion-button
            fill="flat"
            class="mt-2 float-right"
            @click="updateCurrentBookingView('previousPreview')"
            v-if="
              currentBookingView !== 'schedulePreview' &&
              currentBookingView !== 'successfulPreview'
            "
          >
            <i class="fa fa-chevron-left pr-1"></i> Prev
          </ion-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ion-button
            fill="outline"
            expand="block"
            class="mx-1 my-2"
            @click="updateCurrentBookingView('bookingPreview')"
            v-if="currentBookingView === 'schedulePreview'"
          >
            <i class="fa fa-clock-o pr-2"></i>
            Book session
          </ion-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ion-button
            fill="outline"
            expand="block"
            class="mx-1 my-2"
            @click="updateCurrentBookingView('completePreview')"
            v-if="
              currentBookingView === 'bookingPreview' &&
              sessionInsufficient >= compTotalServiceDuration
            "
          >
            <i class="fa fa-handshake-o pr-2"></i>
            Complete booking
          </ion-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ion-button
            fill="outline"
            expand="block"
            class="mx-1 my-2"
            @click="resetServicesRequest()"
            v-if="currentBookingView === 'successfulPreview'"
          >
            <i class="fa fa-refresh pr-2"></i>
            Book another session
          </ion-button>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonAvatar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, computed, watch } from "vue";
import {
  arrowBack,
  mail,
  personAdd,
  star,
  starOutline,
  calendarOutline,
} from "ionicons/icons";

import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";

import compFavCircleActions from "@/views/fav/compFavCircleActions.vue";
import CreateFeedPostServiceBooking from "@/components/forms/feed/CreateFeedPostServiceBooking.vue";
import AccountProfileInfo from "@/components/account/AccountProfileInfo.vue";
import AccountPosts from "@/components/account/AccountPosts.vue";
import AccountRatings from "@/components/account/AccountRatings.vue";

export default defineComponent({
  name: "FeedBooking",
  components: {
    IonContent,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonItem,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAvatar,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    CreateFeedPostServiceBooking,
    AccountProfileInfo,
    AccountPosts,
    AccountRatings,
    IonTitle,
    compFavCircleActions,
  },
  props: {
    currentProvider: {
      required: true,
      default: { owner_id: "none", delivery_mode: "remote" },
    },
    personalPage: {
      required: false,
      default: false,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    const cash = moneyInfo();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    const router = useRouter();

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    const currentProviderServices: any = ref([]);
    const resetProviderServices: any = ref([]);
    const loadRequiredData: any = ref(false);

    const isAuthenticated = ref(vueStore.getters["auth/getSessionData"]);

    // Example of a GET request
    const getAccountProviderServices = async (provider_id) => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url:
          coreUrl.backendUrl +
          "get_account_provider_services?filter=active&provider_id=" +
          provider_id,
        headers: { Authorization: "Bearer " + tokenId },
      };

      // const response: HttpResponse = await CapacitorHttp.get(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        const services_data = response.data.data.account_services
          ? response.data.data.account_services
          : [];
        currentProviderServices.value = services_data;

        currentProviderServices.value =
          await currentProviderServices.value.filter(
            (item) => item.delivery_mode === activeDeliveryModeFilter.value
          );

        for (let i = 0; i < currentProviderServices.value.length; i++) {
          const provider_delivery_mode =
            props.currentProvider.delivery_mode.replace("_", "-");
          const delivery_mode =
            currentProviderServices.value[i]["delivery_mode"];
          currentProviderServices.value[i]["delivery_mode"] = delivery_mode
            ? delivery_mode
            : provider_delivery_mode;
        }

        resetProviderServices.value = services_data;
        loadRequiredData.value = true;
      }
    };

    // alert()

    getAccountProviderServices(props.currentProvider.owner_id);

    const reqStatus = ref("ready");

    const selectedServiceType = ref("default_service");

    const compTotalService = computed(function () {
      const serviceList = currentProviderServices.value;
      const activeService = serviceList.filter(
        (item) => item.status === "selected"
      );
      return activeService;
    });

    const compTotalServiceDuration = computed(function () {
      let totalDuration: any = 0;

      const serviceList = currentProviderServices.value;
      serviceList.filter(function (item) {
        if (item.status === "selected") {
          totalDuration = parseInt(totalDuration) + parseInt(item.duration);
        }
      });

      return totalDuration;
    });

    const compTotalServiceCost = computed(function () {
      let totalCost: any = 0;

      const serviceList = currentProviderServices.value;
      // const activeService =
      serviceList.filter(function (item) {
        if (item.status === "selected") {
          totalCost = parseInt(totalCost) + parseInt(item.cost);
        }
      });
      return totalCost;
    });

    const compTotalServiceCurrency = computed(function () {
      const currency: any = props.currentProvider["currency"];

      return currency;
    });

    function updateCurrentProviderServices(data, serviceType) {
      selectedServiceType.value = serviceType;
      currentProviderServices.value = data;
    }

    const feedPostBookingKey = ref(true);
    async function resetServicesRequest() {
      feedPostBookingKey.value = false;

      setTimeout(() => {
        feedPostBookingKey.value = true;
      }, 2000);

      currentBookingView.value = "schedulePreview";

      for (let i = 0; i < currentProviderServices.value.length; i++) {
        currentProviderServices.value[i]["status"] = "active";
      }
    }

    const sessionInsufficient = ref(0);
    function updateCurrentServicesSchedule(time) {
      sessionInsufficient.value = time;
    }

    const currentBookingView = ref("schedulePreview");

    function updateCurrentBookingView(currentView) {
      if (currentView === "previousPreview") {
        if (currentBookingView.value === "bookingPreview") {
          currentBookingView.value = "schedulePreview";
        }

        if (currentBookingView.value === "completePreview") {
          currentBookingView.value = "bookingPreview";
        }
      } else {
        currentBookingView.value = currentView;
      }
    }

    const currentTab = ref("book");

    function switchTab(ev: any) {
      currentTab.value = ev.detail.value;
    }

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    // const activeDeliveryModeFilter = ref(
    //   props.currentProvider.delivery_mode === "hybrid"
    //     ? "remote"
    //     : props.currentProvider.delivery_mode
    // );

    const activeDeliveryModeFilter = ref("");

    const serviceDeliveryMode = ref([
      { value: "", alias: "Select a service delivery mode" },
      { value: "remote", alias: "Remote services" },
      { value: "in-person", alias: "In-person services" },
    ]);

    function updateActiveDeliveryModeFilter(delivery_mode) {
      activeDeliveryModeFilter.value = delivery_mode;
    }

    watch(activeDeliveryModeFilter, (currentValue, _oldValue) => {
      let tempServices: any = [];

      for (let i = 0; i < resetProviderServices.value.length; i++) {
        const provider_delivery_mode =
          props.currentProvider.delivery_mode.replace("_", "-");
        const delivery_mode = resetProviderServices.value[i]["delivery_mode"];
        resetProviderServices.value[i]["status"] = "active";
        resetProviderServices.value[i]["delivery_mode"] = delivery_mode
          ? delivery_mode
          : provider_delivery_mode;
      }

      if (currentValue === "in-person" || currentValue === "remote") {
        tempServices = resetProviderServices.value.filter(
          (item) =>
            item.delivery_mode === currentValue ||
            item.delivery_mode === "hybrid"
        );
        currentProviderServices.value = tempServices;
      } else {
        tempServices = resetProviderServices.value.filter(
          (item) => item.delivery_mode === currentValue
        );
        currentProviderServices.value = tempServices;
      }
    });

    return {
      tk,
      cash,
      isAuthenticated,
      currentTab,
      switchTab,
      cancelModal,
      arrowBack,
      star,
      starOutline,
      calendarOutline,
      imgBaseAvatarUrl,
      mail,
      personAdd,
      selectedServiceType,
      loadRequiredData,
      currentProviderServices,
      updateCurrentProviderServices,
      feedPostBookingKey,
      resetServicesRequest,
      updateCurrentServicesSchedule,
      compTotalService,
      compTotalServiceDuration,
      compTotalServiceCost,
      compTotalServiceCurrency,
      currentBookingView,
      sessionInsufficient,
      updateCurrentBookingView,
      updateActiveDeliveryModeFilter,
      reqStatus,
      activeDeliveryModeFilter,
      serviceDeliveryMode,
    };
  },
});
</script>

<style scoped>
/* ion-modal::part(content) {
     display: flex;
    flex-direction: column;
    height: 100%;
} */

ion-action-sheet.my-custom-class {
  --background: #eee;
  --backdrop-opacity: 0.6;
  --button-background-selected: #e97223;
  --button-color: #333;
  --color: #000;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

ion-toolbar {
  --background: #fff;
}

ion-datetime {
  --background: #f5f5f5;
}
</style>
