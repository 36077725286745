<template>
  <div v-if="accountDataInfo.expertise">
    <main
      class="col order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 p-0"
    >
      <div
        class="bg-white mb-1"
        :class="canvasType === 'page' ? 'box shadow-sm rounded' : ''"
      >
        <div class="box-title border-bottom p-3">
          <h6 class="m-0">
            What we do
            <span
              class="float-right pointer-cursor"
              style="color: #333"
              @click="openProfileDataUpdateForm((post_type = 'profile_info'))"
              v-if="personalPage"
            >
              <span class="text-small">edit</span>
            </span>
          </h6>
        </div>
        <div class="box-body p-3">
          <p>
            {{ accountDataInfo.bio }}
          </p>
        </div>
      </div>

      <div
        class="bg-white mb-2 mt-4"
        :class="canvasType === 'page' ? 'box shadow-sm rounded' : ''"
      >
        <div class="box-title border-bottom p-3">
          <h6 class="m-0">
            Business hours
            <span class="float-right pointer-cursor" v-if="personalPage">
              <a
                href="/schedule_manager/appointment_booking_settings"
                style="font-size: 15px; color: #333"
              >
                <span class="text-small">edit</span>
              </a>
            </span>
          </h6>
        </div>
        <div class="box-body p-0">
          <ion-list>
            <ion-item
              lines="full"
              class="pt-3"
              v-for="(day, propertyName, index) in compAppointmentInfo"
              :key="propertyName + index"
            >
              <ion-label>
                <h3>{{ day.alias }}</h3>
              </ion-label>
              <ion-label color="dark" slot="end" class="pr-2">
                <h3>
                  {{
                    day.status
                      ? tk.getTimeAlias(
                          day.quota_start_time,
                          day.quota_end_time
                        )
                      : "Closed"
                  }}
                </h3>
              </ion-label>
              <ion-icon
                :icon="day.status ? checkmarkCircle : closeCircleOutline"
                color="dark"
                slot="end"
              ></ion-icon>
            </ion-item>
          </ion-list>
        </div>
      </div>

      <div
        class="bg-white mb-2 mt-4"
        :class="canvasType === 'page' ? 'box shadow-sm rounded' : ''"
      >
        <div class="box-title border-bottom p-3">
          <h6 class="m-0">
            Technical expertise

            <span
              class="float-right pointer-cursor"
              style="font-size: 15px; color: #333"
              @click="openProfileDataUpdateForm((post_type = 'skills'))"
              v-if="personalPage"
            >
              <span class="text-small">edit</span>
            </span>
          </h6>
        </div>
        <div class="box-body p-3">
          <div
            v-if="
              JSON.parse(accountDataInfo.skills) &&
              JSON.parse(accountDataInfo.languages)
            "
          >
            <ion-chip
              fill="solid"
              size="small"
              color="light"
              class="m-2 text-lowercase p-3"
              v-for="(skill, index) in JSON.parse(accountDataInfo.skills)"
              :key="skill + index"
            >
              {{ skill }}
            </ion-chip>

            <ion-chip
              fill="solid"
              size="small"
              color="light"
              class="m-2 p-3 text-capitalize"
              v-for="(lang, index) in JSON.parse(accountDataInfo.languages)"
              :key="lang + index"
            >
              Fluent in {{ lang }}
            </ion-chip>
          </div>
          <div v-else>You have not listed any valuable skill.</div>
        </div>
      </div>

      <div
        class="bg-white mb-2 mt-4"
        :class="canvasType === 'page' ? 'box shadow-sm rounded' : ''"
      >
        <div class="box-title border-bottom p-3">
          <h6 class="m-0">
            Policy & perks

            <span
              class="float-right pointer-cursor"
              style="font-size: 15px; color: #333"
              @click="openProfileDataUpdateForm((post_type = 'rules'))"
              v-if="personalPage"
            >
              <span class="text-small">edit</span>
            </span>
          </h6>
        </div>
        <div class="box-body">
          <div v-if="compPolicyPerksList.length > 0">
            <ion-list
              :inset="true"
              color="light"
              class="mx-0 my-2 px-0"
              lines="full"
            >
              <ion-item
                class="pt-1"
                v-for="(perk, index) in compPolicyPerksList"
                :key="perk + index"
              >
                <ion-label>
                  <span class="material-symbols-rounded sidebar-icon-list"
                    >handshake</span
                  >
                  {{ perk }}</ion-label
                >
              </ion-item>
            </ion-list>
          </div>
          <div class="p-3" v-else>
            You have not listed any perks & operational policies. This could be
            used strategically to encourage clients to come to you.
          </div>
        </div>
        <div
          class="p-3 d-flex justify-content-center"
          v-if="compAllPolicyPerks.length > 5"
        >
          <span
            class="btn btn-lg font-weight-bold pointer-cursor"
            @click="togglePolicyPerksListSize"
          >
            {{
              showLessPolicyPerks
                ? "Show all policies & perks"
                : "Show less polices & perks"
            }}
          </span>
        </div>
      </div>
    </main>
  </div>
  <div v-else>
    <div v-if="personalPage">
      <p>
        You are yet to complete your profile. We recommend that you do, to boost
        your chances of landing clients.
      </p>
      <router-link
        to="/account_basic_info"
        class="btn-lrg-standard btn-contact-me js-contact-me js-open-popup-join"
      >
        <i class="fa fa-pencil fa-fw mt-2 mb-3"></i>
        Update profile
      </router-link>
    </div>
    <div v-else>
      <p>This provider is yet to update their basic information.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, inject } from "vue";
import {
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonChip,
  modalController,
} from "@ionic/vue";
import { closeCircleOutline, checkmarkCircle } from "ionicons/icons";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";

import AccountUpdateProfileInfo from "@/components/account/AccountUpdateProfileInfo.vue";
import AccountUpdateSkills from "@/components/account/AccountUpdateSkills.vue";
import AccountUpdateServices from "@/components/account/AccountUpdateServices.vue";
import AccountUpdatePolicyPerks from "@/components/account/AccountUpdatePolicyPerks.vue";

export default defineComponent({
  name: "AccountProfileInfo",
  components: {
    IonIcon,
    IonItem,
    IonList,
    IonChip,
    IonLabel,
  },
  props: {
    accountDataInfo: {
      required: true,
      default: { owner_id: "none" },
    },
    personalPage: {
      required: true,
      default: true,
    },
    canvasType: {
      type: String,
      default: "page",
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    const cash = moneyInfo();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global route object
    const router = useRouter();

    const isMobile = ref(inject("isMobile"));

    const appointmentInfo: any = ref({
      monday: {
        alias: "Monday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        monday_custom_slots: [],
      },
      tuesday: {
        alias: "Tuesday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        tuesday_custom_slots: [],
      },
      wednesday: {
        alias: "Wednesday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        wednesday_custom_slots: [],
      },
      thursday: {
        alias: "Thursday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        thursday_custom_slots: [],
      },
      friday: {
        alias: "Friday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        friday_custom_slots: [],
      },
      saturday: {
        alias: "Saturday",
        type: "quota",
        status: true,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        saturday_custom_slots: [],
      },
      sunday: {
        alias: "Sunday",
        type: "quota",
        status: false,
        quota_start_time: true,
        quota_end_time: true,
        quota_time_off: [],
        quota_duration: "30",
        quota_buffer: "10",
        sunday_custom_slots: [],
      },
    });

    const isSetAppointmentInfo = ref("default");

    // Example of a GET request
    const getProviderAppointmentWindow = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "get_provider_appointment_window",
        headers: { Authorization: "Bearer " + tokenId },
        params: { provider_id: props.accountDataInfo.owner_id },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        appointmentInfo.value = await response.data.data.appointment_info;
        isSetAppointmentInfo.value = "loaded";
      }
    };

    getProviderAppointmentWindow();

    const compAppointmentInfo = computed(function () {
      let updatedAppointmentInfo: any = appointmentInfo.value;

      if (isSetAppointmentInfo.value === "loaded") {
        const currentApptData = appointmentInfo.value;
        updatedAppointmentInfo = {
          monday: {
            alias: "Monday",
            type: currentApptData.monday_window_type,
            status: currentApptData.monday_window_status,
            quota_start_time: currentApptData.monday_quota_start_time,
            quota_end_time: currentApptData.monday_quota_end_time,
            quota_time_off: currentApptData.monday_quota_time_off,
            quota_duration: currentApptData.monday_quota_duration,
            quota_buffer: currentApptData.monday_quota_buffer,
            custom_slots: currentApptData.monday_custom_slots,
          },
          tuesday: {
            alias: "Tuesday",
            type: currentApptData.tuesday_window_type,
            status: currentApptData.tuesday_window_status,
            quota_start_time: currentApptData.tuesday_quota_start_time,
            quota_end_time: currentApptData.tuesday_quota_end_time,
            quota_time_off: currentApptData.tuesday_quota_time_off,
            quota_duration: currentApptData.tuesday_quota_duration,
            quota_buffer: currentApptData.tuesday_quota_buffer,
            custom_slots: currentApptData.tuesday_custom_slots,
          },
          wednesday: {
            alias: "Wednesday",
            type: currentApptData.wednesday_window_type,
            status: currentApptData.wednesday_window_status,
            quota_start_time: currentApptData.wednesday_quota_start_time,
            quota_end_time: currentApptData.wednesday_quota_end_time,
            quota_time_off: currentApptData.wednesday_quota_time_off,
            quota_duration: currentApptData.wednesday_quota_duration,
            quota_buffer: currentApptData.wednesday_quota_buffer,
            custom_slots: currentApptData.wednesday_custom_slots,
          },
          thursday: {
            alias: "Thursday",
            type: currentApptData.thursday_window_type,
            status: currentApptData.thursday_window_status,
            quota_start_time: currentApptData.thursday_quota_start_time,
            quota_end_time: currentApptData.thursday_quota_end_time,
            quota_time_off: currentApptData.thursday_quota_time_off,
            quota_duration: currentApptData.thursday_quota_duration,
            quota_buffer: currentApptData.thursday_quota_buffer,
            custom_slots: currentApptData.thursday_custom_slots,
          },
          friday: {
            alias: "Friday",
            type: currentApptData.friday_window_type,
            status: currentApptData.friday_window_status,
            quota_start_time: currentApptData.friday_quota_start_time,
            quota_end_time: currentApptData.friday_quota_end_time,
            quota_time_off: currentApptData.friday_quota_time_off,
            quota_duration: currentApptData.friday_quota_duration,
            quota_buffer: currentApptData.friday_quota_buffer,
            custom_slots: currentApptData.friday_custom_slots,
          },
          saturday: {
            alias: "saturday",
            type: currentApptData.saturday_window_type,
            status: currentApptData.saturday_window_status,
            quota_start_time: currentApptData.saturday_quota_start_time,
            quota_end_time: currentApptData.saturday_quota_end_time,
            quota_time_off: currentApptData.saturday_quota_time_off,
            quota_duration: currentApptData.saturday_quota_duration,
            quota_buffer: currentApptData.saturday_quota_buffer,
            custom_slots: currentApptData.saturday_custom_slots,
          },
          sunday: {
            alias: "Sunday",
            type: currentApptData.sunday_window_type,
            status: currentApptData.sunday_window_status,
            quota_start_time: currentApptData.sunday_quota_start_time,
            quota_end_time: currentApptData.sunday_quota_end_time,
            quota_time_off: currentApptData.sunday_quota_time_off,
            quota_duration: currentApptData.sunday_quota_duration,
            quota_buffer: currentApptData.sunday_quota_buffer,
            custom_slots: currentApptData.sunday_custom_slots,
          },
        };
      }

      return updatedAppointmentInfo;
    });

    const modalFormList = {
      profile_info: AccountUpdateProfileInfo,
      skills: AccountUpdateSkills,
      service_create: AccountUpdateServices,
      service_update: AccountUpdateServices,
      service_delete: AccountUpdateServices,
      rules: AccountUpdatePolicyPerks,
    };

    function togglePolicyPerksListSize() {
      showLessPolicyPerks.value = !showLessPolicyPerks.value;
    }

    const compAccountDataInfo = computed(function () {
      return props.accountDataInfo;
    });

    const compAllPolicyPerks = computed(function () {
      let accountInfo: any = props.accountDataInfo["policy_perks"];

      accountInfo = JSON.parse(props.accountDataInfo["policy_perks"])
        ? JSON.parse(props.accountDataInfo["policy_perks"])
        : [];

      return accountInfo;
    });

    const showLessPolicyPerks = ref(
      compAllPolicyPerks.value.length > 5 ? true : false
    );

    const compPolicyPerksList = computed(function () {
      let currList = compAllPolicyPerks.value;

      if (showLessPolicyPerks.value) {
        currList = currList.slice(0, 5);
      }

      return currList;
    });

    async function openProfileDataUpdateForm(
      post_type: any = "none",
      service_index: any = "none"
    ) {
      const modal = await modalController.create({
        component: modalFormList[post_type],
        mode: "ios",
        cssClass: "",
        componentProps:
          post_type === "profile_info" ||
          post_type === "skills" ||
          post_type === "username"
            ? { actionType: "settings" }
            : {
                actionType: "settings",
                postType: post_type,
                serviceIndex: service_index,
              },
      });
      modal.present();

      await modal.onWillDismiss();
      // const { data, role } = await modal.onWillDismiss();

      // if(role === "confirm"){
      //     isSetAppointmentInfo.value = "updated"
      //     appointmentInfo.value = data
      // }
    }

    return {
      tk,
      cash,
      isMobile,
      appointmentInfo,
      openProfileDataUpdateForm,
      compAppointmentInfo,
      closeCircleOutline,
      checkmarkCircle,
      showLessPolicyPerks,
      togglePolicyPerksListSize,
      compAccountDataInfo,
      compAllPolicyPerks,
      compPolicyPerksList,
    };
  },
});
</script>

<style scoped>
.sidebar-icon-list {
  font-size: 16px;
}
</style>
